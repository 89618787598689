<template>
  <input :name="csrfParam" :value="csrfToken" type="hidden" />
</template>

<script setup lang="ts">
import Rails from '@rails/ujs';

const csrfParam = Rails.csrfParam();
const csrfToken = Rails.csrfToken();
</script>
