import jsonStorageFactory from 'json-storage-factory';
import { ComboStatus } from '@/types/models';

type QuestionSubjectPracticeFormBase = {
  comboStatusChecks: ComboStatus[],
  pickup: boolean,
  favoriteGroupIds: number[],
  favoriteGroupCondition: 'any' | 'all'
  tagIds: number[],
  questionLimit: string,
  corporation: boolean,
  isPremium: boolean,
}
type QuestionSubjectPracticeFormChecks = { [key: string]: boolean }
type QuestionSubjectPracticeFormData = {
  base: QuestionSubjectPracticeFormBase,
  checks: QuestionSubjectPracticeFormChecks
}

const questionSubjectPracticeFormStorage = (key: string) => {
  const storage = jsonStorageFactory<QuestionSubjectPracticeFormData>(() => sessionStorage);

  const save = (data: QuestionSubjectPracticeFormData): void => {
    storage.setItem(key, data);
  };

  const load = (): QuestionSubjectPracticeFormData => {
    const data = storage.getItem(key);
    if (data) { return data; }

    return {
      base: {
        comboStatusChecks: ['first_time', 'miss'],
        pickup: false,
        favoriteGroupIds: [],
        favoriteGroupCondition: 'any',
        tagIds: [],
        questionLimit: '10',
        corporation: false,
        isPremium: false,
      },
      checks: {},
    };
  };

  const remove = (): void => {
    storage.removeItem(key);
  };

  return { save, load, remove };
};

export { QuestionSubjectPracticeFormBase, questionSubjectPracticeFormStorage };
